import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getstorelist } from '../API'
import { SliderData } from '../Data/Storelist'
import Footer from './Footer'
import Header from './Header'

function StoreList() {
  const navigate =useNavigate()
  const [language , setlanguage] = useState('English')

  const handlestoreclick=(item)=>{
    // navigate(`/storelist/${item._id}`,{state:item})
    navigate('/selectDetails', {state:item}) 
  }
  const [storelist, setstorelist] =useState()
  useEffect(()=>{
    getstorelist().then((res)=>{
        console.log('Storelist response',res)
        setstorelist(res.data)
    }).catch((err)=>{
           console.log(err)
    })
  },[])

    return (
    <div className='MainWhole-wrapper-container'>  
      <Header/>
      <div className='ItemListMain-Wrapper'>
        <div className="ItemList_page_Content_wrapper"> 
           <h1> Hello @Concourse A</h1>
            <p> We’ve got great options if you’ve got time.</p>
        </div>
        <div className='Storelist-container-wrapper'>
            <h3> 3 stores near you </h3>
         {SliderData.map(item=>{
            return(
             <div className='Storelist_container_inner_item_wrapper' onClick={item.status ==='inactive'?'': ()=>handlestoreclick(item)}> 
                <img src={item.img} className={item.status ==='inactive'? "inactive_store":''}/>
                {item.status ==='inactive'? <span className="Storelist_page_comingsoon_img"><img src="/assets/images/coming-soon.svg"/></span>:''} 
                    <div className='Storelist_container_inner_item_wrapper_content_div'> 
                        <h4>{item.title}</h4>
                        {/* <h6>{item.distance}</h6> */}
                        <p> {item.description}</p>   
                        <h6 className={item.status ==='inactive'? "inactive_store":''}>{item.address}</h6>  
                    </div> 
             </div>
            )
         })}   
          
       </div> 
      </div>
      <Footer/>
    </div>
  )
}

export default StoreList
