import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { orderbooking } from '../API'
import Footer from './Footer'
import HeaderRedirecting from './HeaderRedirecting'

function Redirecting() {
   
    const data= useLocation()

    useEffect(()=>{
       
        setTimeout(()=>{
         window.location.replace(`https://qaquickpay.hmshost.com/menu/112/208?orderId=${data.state.randomorderId}`)
        }, 3000)
    },[])

   
  return (
    <div className="MainWhole-wrapper-container">
        {console.log('data',data)}
        <HeaderRedirecting/>
         <div className='Redirecting_page_content_wrapper'> 
                 <div className='Redirecting_page_content_wrapper_div_one'> 
                    <img src="/assets/images/Loader.svg"/>
                </div>
                <div className='Redirecting_page_content_wrapper_div_two'> 
                    <img src="/assets/images/Spinner-1s-200px.svg"/>
                </div>  
                <div className='Redirecting_page_content_wrapper_div_three'> 
                    <p> Redirecting to the ordering App</p>
                    <img src="/assets/images/HMS_Logo.png"/>
                </div>    
         </div>
     <Footer/>    
    </div>
  )
}

export default Redirecting