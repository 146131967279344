import React from 'react'
import Header from './Header'
import { useTranslation } from "react-i18next";
function MoreDetails() {
 
 

    const {t}= useTranslation()  
    return (
    <>
     <div className='MainWhole-wrapper-container'>
   <Header/>
     <div className="MoreDetials_page_Banner_wrapper"> 
        <img src="/assets/images/shoptogate.png"/>
        <img src="/assets/images/logo_header_desk_h_color.svg"/>
     </div>
      <div style={{backgroundColor:"white"}}> 
        <h2 className="More_Details_Page_h2"> {t('More_Details_page_heading_h2')}</h2>
         <div style={{marginTop:'0px', padding:'20px'}}>   
           <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_1')}</h5>
           <p className="More-details-page-content-p"> {t('More_Details_page_content_p_1')}</p>
         </div>
          <div style={{marginTop:'0px', padding:'20px'}}> 
          <h5 className="More_Details_Page_h5">{t('More_Details_page_content_h5_2')} </h5>
          <p className="More-details-page-content-p"> {t('More_Details_page_content_p_2')}</p>
         </div>
         <div style={{marginTop:'0px', padding:'20px'}}>
          <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_3')}</h5>
          <p className="More-details-page-content-p"> {t('More_Details_page_content_p_3')}
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_4')}  </p>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_5')} </p>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_6')} </p>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_7')} </p>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_8')}  </p>
            </p>
        </div> 
        <div style={{marginTop:'0px', padding:'20px'}}>
          <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_4')}</h5>
          <p className="More-details-page-content-p"> {t('More_Details_page_content_p_9')}</p>
        </div>
     </div> 
  </div> 
    </>
  )
}

export default MoreDetails