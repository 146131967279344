import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next';
import { useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
function Header(props) {

  const[currentlanguage, setcurrentlanguage] =useState("Italian")
  const language = localStorage.getItem('language')
  const handlelogoutbtn=()=>{
	   localStorage.setItem('email',null)
       localStorage.setItem('password',null)
	   localStorage.setItem('language', null)
  }
  const navigate= useNavigate()
  const handleBackbutton=()=>{
	navigate(-1)
  } 
  const [isscroll, setisscroll]=useState(false)
  useEffect(()=>{
	window.addEventListener('Scroll', setisscroll(!isscroll));
  },[])

  const handlehelp=()=>{
     navigate('/help')
  }
   return (
    <div className={props.open || props.opentwo ? 'MainHeader-wrapper_copy headerscroll':'MainHeader-wrapper'}>
		{console.log('isscroll',isscroll)}
       <div className='MainHeader-logo-div-wrapper'>
	   <div className='MainHeader-logo-div'>
        <p> <ArrowBackIosNewIcon onClick={handleBackbutton} style={{cursor:'pointer', fontSize:'39.4px', color:"#40B4D0"}}/> </p>
		 <Link to="/" onClick={handlelogoutbtn}><img src="/assets/images/orderatcvg.png" />
		 </Link>
	   </div>  
       <div className='MainHeader_second_div'>
            
		<span style={{ marginTop: '5px' , cursor:'pointer'}} onClick={handlehelp}> <HelpOutlineOutlinedIcon sx={{color:'#40B4D0'}}/> </span>  Help
        {/* <FormControl variant="outlined" style={{width:'100%'}} >	  
							    <Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									displayEmpty
									value={language}
									onChange={(e)=>{
                                      setcurrentlanguage(e.target.value)
                                    }}
									style={{width:'100%',height: '39px',borderRadius: '10px', border:'1px solid rgb(242,242,242)'}}
									placeholder="Select Language"
									>  
								     <MenuItem onClick={()=> {
										i18next.changeLanguage('en')
										localStorage.setItem('language',"English")
										}} key={""} defaultSelected={currentlanguage} value="English" style={{textAlign:'center'}}>
										<img src="/assets/images/2560px-Flag_of_the_United_States.svg.png" style={{width:'20px', height:'20px', marginRight:'10px'}}/><span>English</span>
									</MenuItem>
								    <MenuItem onClick={()=> {
									  i18next.changeLanguage('it')
									localStorage.setItem('language',"Italian")
									}} key={""} value="Italian" name="Italian">
										<img src="/assets/images/Flag_of_Italy.svg (1).png" style={{width:'20px', height:'20px', marginRight:'20px', marginRight:'10px'}}/><span>Italian</span>
									</MenuItem>
											
									</Select>
              </FormControl>	       */}
             {/* <div id="google_translate_element"></div>     */}
      </div>
	 </div>   
    </div>
  )
}

export default Header