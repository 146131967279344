import React from 'react'

function Footer(props) {
  return (
    <div className={props.open || props.opentwo ? 'Footer_Content_wrapper_copy':"Footer_Content_wrapper"}>
        <p> Powered By</p>
        <img src="/assets/images/ottonomy-svg.png"/>
    </div>
  )
}

export default Footer