import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import axios from 'axios'

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const cartData = useLocation()
  useEffect(() => {
    axios.get('http://localhost:4000/config',{
        headers:{
            'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': "*"
        }
    }).then(async (r) => {
      const { publishableKey } = await `${r.data.publishableKey}`
      setStripePromise(loadStripe(r.data.publishableKey));
      
    });
   }, []);

  useEffect(() => {
    let totalpricearray=[]
    cartData.state.state.cartData.map((item)=>{
      return totalpricearray.push(item.count*item.price)
    })
   const totalprice= totalpricearray.reduce((a, b) => a + b, 0)
   axios.post('http://localhost:4000/create-payment-intent',{
    totalprice:totalprice
  }, {
    headers:{
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': "*"
    }
}).then(async (result) => {
    console.log('result',result)
    var { clientSecret } = await `${result.data.clientSecret}`;
    setClientSecret(result.data.clientSecret);
  });
    // fetch("http://localhost:4000/create-payment-intent", {
    //   method: "POST",
    //   body: JSON.stringify({totalprice:totalprice}),
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': "*",
    //     'Access-Control-Allow-Methods': "*"
    //   },
    // })
   }, []);

  return (
    <>
 {console.log('cartData',cartData)}
   <div className='MainWhole-wrapper-container'> 
		<Header/>
		<div className="Checkout-StripeMain-wrapper">
			<h1 >
				Stripe
			</h1>
			<h3> 
				Enter Your Payment Details
				</h3> 
                {clientSecret && stripePromise && (
           <Elements stripe={stripePromise} options={{ clientSecret }}>
             <CheckoutForm />
          </Elements>
          )}
		</div>	
    	</div>
     
    </>
  );
}

export default Payment;
