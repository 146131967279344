import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ProductPageHeader from './ProductPageHeader'
import Rating from '@mui/material/Rating';
import { DecrementCount, IncrementCount } from '../redux/Actions'
import { productlistdata } from '../Data/Storelist'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer'
import { useEffect } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getproductlist } from '../API'

function Productlist() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor:'#ffffff',
        boxShadow: 24,
        p: 4,
        borderRadius:'10px',
        paddingTop: '11px',
        border:'none',
        paddingRight:'0px',
        paddingLeft:'0px',
        zIndex:'999999999999999999999999'
      };
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const storedata= useLocation() 
   const [menu, setmenu] = useState('')
   const [ratingvalue, setratingValue] = useState(3);
   let lastdata=[]
   const dispatch =useDispatch() 
   const [changestate, setchanegState] = useState(false)
   const countdata=useSelector(state=> state.ItemCounter)
   const [productlist, setproductlist] =useState()
   const [cartweight, setcartweight]= useState(0)

   useEffect(()=>{
    productlistdata.map((item)=>{
        item.addbtn=false
    })
   setTimeout(()=>{
    handleOpen()
   },2000)
   getproductlist().then((res)=>{
         console.log('Product list Response', res)
         setproductlist(res.data)
   }).catch((err)=>{
        console.log("Error in product list response", err)
   })
   },[])
   const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
  const navigate= useNavigate()
  const handleNextButton=()=>{
   navigate('/selectDetails', {state:lastdata}) 
  }
  return (
    <div className='MainWhole-wrapper-container'>
        <ProductPageHeader storedata={storedata} open={open}/>
        
       <div className='Productlist_page_content_wrapper'> 
         <div className='Productlist_page_menu_wrapper'> 
             <h3> Menu</h3>
             <BottomNavigation
                showLabels
                name="deliverylocation"
                value={menu}
                onChange={(event, newValue) => {
                    setmenu(newValue)    
                }}
                style={{ flexWrap:'wrap', position:'relative', display:'flex', textAlign:'start' , height:'44px',background:'transparent', overflowX:'scroll', flexDirection:"column",paddingBottom: '27px'}}>
                  <BottomNavigationAction label='Burgers' className="Single_btn_select_delivery_location" value='Burgers' style={{fontWeight:"bold",padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'24px', fontFamily:'Poppins', color:'', height:"unset"}} />
                  <BottomNavigationAction label='French Fries' className="Single_btn_select_delivery_location" value='French Fries' style={{fontWeight:"bold",padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'24px', fontFamily:'Poppins', color:'', height:"unset"}} />
                  <BottomNavigationAction label='Beverages' className="Single_btn_select_delivery_location" value='Beverages' style={{fontWeight:"bold",padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'24px', fontFamily:'Poppins', color:'', height:"unset"}} />  
            </BottomNavigation>  
         </div>
       
         <div className='Productlist_page_productlist_wrapper'> 
         {productlistdata.map(items=>{
            return(
                <div className="Productlist_page_individual_product"> 
                <div className="Productlist_page_individual_product_content_wrapper">
                     <h2> {items.title}</h2>
                     <p> ₹{items.price} </p>
                      <div className='Productlist_page_rating_div'>  
                     <Rating name="read-only" value={items.rating} readOnly /> <span> {items.rating}</span>
                     </div>
                      <p> {items.description}</p>
                </div>  
                 <div className="Productlist_page_individual_product_image_wrapper"> 
                      <img src={items.image}/>
                   <div className='Productlist_product_counter_wrapper'>
                               {items.addbtn ?  
                                     <>
                                     <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                      dispatch(DecrementCount(items, items.id))
                                      // handlecartupdatetwo(items.id)
                                     }}> - </button>
                                     <input type="number" readOnly value={items.count} className="ItemsListPage-ItemsList-Counter-input"/>
                                     <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                         // handlecartupdate(items.id)
                                      dispatch(IncrementCount(items, items.id))
                                     }}> + </button>  </>: 
                                 <button onClick={()=>{
                                    items.addbtn=true
                                    setchanegState(!changestate)
                                    dispatch(IncrementCount(items, items.id))
                                 }}> Add</button>}    
                                    
                   </div> 
                </div> 
                           {(()=>{
                           if(items.count>0){
                            lastdata.push(items)
                           }
                           else if(items.count <=0){
                             lastdata=lastdata.filter(item=> items.id!== item.id)
                             items.addbtn=false
                           }
                           })()}
            </div> 
            )
         })}
          
         </div>
       </div> 
        <div className='Productlist_page_menu_icon_wrapper'>
        <MenuIcon sx={{fontSize:'40px'}}/>
        </div>
      {lastdata.length>0 ? 
      <div className="ProductList_page_next_button_wrapper"> 
        <div className="ProductList_page_next_button_wrapper_inner" onClick={handleNextButton}> 
           NEXT  <ArrowForwardIosIcon sx={{position: 'absolute',right: '12px',top: '20px'}}/>
        </div>
      </div> :null}
      <Modal
                open={open}
                // BackdropProps={false}
                onHide={handleClose}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"  
      >  
      <Box sx={style}>
        <div className="Sorry_div_wrapper">
             <div className='Sorry_div_wrapper_inner_div' onClick={handleClose}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            <img src="/assets/images/Emoji_logo.png"/>
              <h4> Sorry !!!</h4>
            <p className='Sorry_div_wrapper_p'>  Cannot add more items. Weight/volume limit reached.</p>
       </div> 
        </Box>
        </Modal> 
    </div> 
  )
}

export default Productlist
