import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { getlocationlistmapId } from '../API';
import Header from './Header';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';

function SelectDelivery() {
    const [deliverylocation,setdeliverylocation] =useState()
    const [activenextbtn ,setactivenextbtn] =useState(false)
    const {t}  = useTranslation() 
    useEffect(()=>{
        getlocationlistmapId().then((res)=>{
             console.log('Location List Map Id', res)
             setdeliverylocation(res.data.data)
        }).catch((err)=>{
             console.log('Error in Map Id Locations',err)
        })
   
      },[])
   const cartData = useLocation()
   const navigate = useNavigate()   

  var dlocation = localStorage.getItem('deliveryLocation')
  const validationSchema = yup.object({
    deliverylocation :yup.string().required('Please Select Delivery location')
})  
  const formik = useFormik({
    initialValues: {
      deliverylocation:'',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.setItem('deliveryLocation', values.deliverylocation)
      navigate('/details', {state:{locationName:values.deliverylocation, cartdata:cartData.state}})
    }
  })
  let newlocation=[]
    return (
        <>
     <div className='MainWhole-wrapper-container'>    
        <Header/>   
      <div className="DetailsPage-Main-wrapper">
         <h2> 
          {/* {t('cart_page_delivery_location')} */}
          Select Delivery Gate
          </h2>
          {console.log('deliverylocation',deliverylocation)}

  
    <h3 className="Select_delivery_page_concourse_page_heading"> Concourse A</h3>
  <form onSubmit={formik.handleSubmit} 
          // style={{height:'100vh'}}
          > 
      <div className='Select_delivery_page_inner_wrapper'> 
        {/* <p className='Select_delivery_page_InnerHeading'>COUNCOURSE B</p>  */}
         <div className='Select_delivery_locations_list_wrapper'>  
          {/* {deliverylocation?.map((item)=>{
            if(item.locationType ==="DROPOFF" && item.status ==="ACTIVE")
              return (
                <div className={dlocation===item.locationName ? 'Select_delivery_location_delivery_name delivery_active':"Select_delivery_location_delivery_name"} onClick={()=>handlelocationclick(item.locationName)}> 
                   {item.locationName}
                </div> )
        })} */}
             <BottomNavigation
                showLabels
                name="deliverylocation"
                value={formik.values.deliverylocation}
                onChange={(event, newValue) => {
                    formik.setFieldValue('deliverylocation',newValue)
                }}
                style={{ position:'relative', display:'inline-block'}}
                error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                helperText={formik.touched.deliverylocation && formik.errors.deliverylocation} 
            >
               {/* <h4 className='delivery_location_page_content_heading'> Concourse B</h4> */}

              
             
                {/* {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" && item.locationName.charAt(0)==='B' )
                    {
                     return newlocation.push(item)
                      // return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 9px',backgroundColor:'white' , margin:'10px !important'}} />
                    } 
                })} */}
              {/* {(()=>{
                newlocation=  newlocation.reverse()
               })()} */}

               {console.log('newlocationnewlocation',newlocation)} 
                {/* {newlocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'15px', fontFamily:'Poppins' }} />
                    } 
                })} */}
               
                {/* <h4 className='delivery_location_page_content_heading'> Concourse D</h4> */}
                 {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE"  )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} />
                    } 
                })}
            </BottomNavigation>
            {(formik.touched.deliverylocation &&formik.errors.deliverylocation)? 
          <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> {t('Detail_page_please_select_delivery_location')}</p>:null }
      </div>  
        </div>
        {/* {formik.values.deliverylocation === '' ? null: */}
        <div className="Select_delivery_page_next_btn_wrapper"> 
        <button className="ItemList_Page_Next_button" type="submit"> {t('Item_list_two_page_Nextbtn_text')} 
        <ArrowForwardIosIcon />
        </button>
        </div>   
       {/* } */}
        </form>
    </div>
    <Footer/> 
   </div> 
    </>  
  )
}

export default SelectDelivery
