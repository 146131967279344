import React from 'react'
import { useNavigate } from 'react-router-dom'

function HeaderRedirecting() {

    const navigate =useNavigate()
  return (
     <div className='Redirecting_header_wrapper'>
         <img src="/assets/images/orderat.io.png" onClick={()=>navigate('/')} style={{cursor:'pointer'}} />
    </div>
  )
}

export default HeaderRedirecting
