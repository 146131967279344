import { BottomNavigation, BottomNavigationAction, InputLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import Header from './Header'
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { getlocationlistmapId, sendMessage } from '../API';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';
function Details() {
    
const[btndisable, setbtndisable] =useState(false)
    const dispatch =useDispatch()
    const otpSuccess = ()=> toast.success('OTP send Successfully')
    const otpError = ()=> toast.error('OTP send Failed')
    const Data = useLocation()
    console.log('Data', Data)
    const cartData=Data.state.cartdata
    const locationName=Data.state.locationName
    const navigate = useNavigate()
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = yup.object({
        contactno :yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
        username:yup.string()
    })  
    const formik = useFormik({
        initialValues: {
           contactno:'',
           username:''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

        let message= Math.floor(100000 + Math.random() * 900000)
        sendMessage(values.contactno,message , values.username)
        .then(res=>{
          console.log('OTP Send Success' ,res)
           if(res.data.status===200 ||res.data.status==="200" ){
            let contactNo = values.contactno
            let username =values.username
            otpSuccess()
            navigate('/cart', {state:{cartData,message,locationName,contactNo,username}})
            setbtndisable(true)  
           }
                 })
        .catch((err)=>{
         console.log('Error in send OTP',err)
         otpError()
        })
        },
      });
      const onValueChange = (value) => {
        formik.setFieldValue('contactno',value)
       };
    const {t}  = useTranslation()   
    return (
    <>
    <div className='MainWhole-wrapper-container'>   
    <Header/>
    {console.log('DataData',Data)}
     <div className="DetailsPage-Main-wrapper">
      {/* <h2> {t('cart_page_heading')}</h2> */}
      <h2> Enter your details</h2>
      <form onSubmit={formik.handleSubmit} 
      // style={{height:'100vh'}}
      >
      <div className="DetailsPage-content-wrapper">
          
      <div className="DetailsPage-content-wrapper-inner"> 
                {/* <InputLabel style={{fontFamily:"'Poppins', sans-serif"}} className='detailspage-content-label'> {t('cart_page_phone_number')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
                <h3 className="DetailsPage-content-wrapper-inner_h3"> Enter Phone Number*</h3>
          <div style={{ marginTop: '10px',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
              
              <div  className="inline"
                style={{ width: '100%',  margin:'auto' }}>
                <PhoneInput
                  style={{marginBottom: '10px', textAlign:'justify'}}
                  country={'us'}
                  specialLabel='Phone Number'
                  enableSearch={true}
                  autoFocus={true}
                  value={formik.values.contactno}
                  onChange={(value)=>onValueChange(value)}
                  countryCodeEditable={false}
                  margin="standard"
                  name="contactno"
                  label="Phone Number"
                  // className="phone-number-verification"
                  required
                  containerStyle={{}}
                  inputStyle={{
                    border: '2px solid rgb(221 219 219)',
                    // borderRadius: '100px',
                    width: '100%',
                    padding:'8px 60px'
                  }}
                  dropdownStyle={{ }} 
                  error={formik.touched.contactno &&
                    Boolean(formik.errors.contactno)}
                  helperText={formik.touched.contactno && formik.errors.contactno}
                />
            
              </div>
              
            </div>
            {formik.errors.contactno && formik.touched.contactno? <p style={{color:'#d32f2f',fontSize:'0.75rem',marginTop: '-2px',textAlign: 'left',paddingLeft: '0px'}}>Please Enter Phone Number</p>:null}
            </div>
            <div className="DetailsPage-content-wrapper-inner Details_page_input_wrapper">
                 
                  <h3 className="DetailsPage-content-wrapper-inner_h3">Name </h3>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="outlined"
                    value={formik.values.username}
                    name="username"
                    onChange={formik.handleChange}
                    placeholder='Name'
                    error={formik.touched.username &&
                      Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                    sx={{backgroundColor:"white" }}
                    /> 
            </div>
            {formik.errors.contactno && formik.touched.contactno? <p style={{color:'#d32f2f',fontSize:'0.75rem',marginTop: '-2px',textAlign: 'left',paddingLeft: '0px'}}>Please Enter UserName</p>:null}
               <div  className='Details_page_selected_deivery_location_wrapper'> 
                  <h4> Selected Gate:   </h4>
                  <p>{locationName}</p> 
              </div>
      </div>
        <div className="Details-page-Nextbutton-wrapper"> 
           {formik.values.contactno==='' ? <div className='Details_page_disabled_button'> Send One-Time Password (OTP) <ArrowForwardIosIcon /></div> : 
            <button type="submit" style={{cursor:'pointer'}}>
              {/* {t('Details_page_next_button')} */}
              Send One-Time Password (OTP)
              <ArrowForwardIosIcon />
           </button> }
        </div>
        </form>   
     </div>
     <ToastContainer/>
     
   </div>
   <Footer/>  
    </>
  )
}

export default Details