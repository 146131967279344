import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import '../src/CSS/custom.css'
import ItemsList from './components/ItemsList';
import Details from './components/Details';
import Cart from './components/Cart';
import Congratulations from './components/Congratulations';
import Checkout from './stripepayment/Checkout';
import Help from './components/Help';
import MoreDetails from './components/MoreDetails';
import HomeSecond from './components/HomeSecond';
import ItemsListTwo from './components/ItemListTwo';
import SelectDelivery from './components/SelectDelivery';
import StoreList from './components/StoreList';
import Productlist from './components/Productlist';
import Payment from './stripepayment/Payment';
import Redirecting from './components/Redirecting';

function App() {
  
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Home/>}/> 
          <Route path="/home" element={<Home/>}/>
          <Route path="/homesecond" element={<HomeSecond/>}/>
          <Route path="/Itemslist" element={<ItemsList/>}/> 
          <Route path="/ItemslistTwo" element={<ItemsListTwo/>}/> 
          <Route path="/Details" element={<Details/>}/>
          <Route path="/cart" element={<Cart/>}/> 
          <Route path="/congratulations" element={<Congratulations/>}/>  
          <Route path="/checkout" element={<Payment/>}/> 
          <Route path="/help" element={<Help/>}/>  
          <Route path="/moredetails" element={<MoreDetails/>}/>  
          <Route path="/selectDetails" element={<SelectDelivery/>}/>  
          <Route path="/storelist" element={<StoreList/>}/> 
          <Route path="storelist/:id" element={<Productlist/>}/> 
          <Route path="/redirecting" element={<Redirecting/>}/>
       </Routes>
    </div>
  );
}

export default App;
