export const SliderData=[
    {  
            _id:101, 
            title:"Smash Burger",
            distance:'4.5 rating,500 miles',
            description:'American fast-casual hamburger restaurant chain founded',
            address:'Near Gate 52 , Concourse A',
            img :'/assets/images/Smash_Burger_image.png',
            status:'active'
     },{
            _id:102,    
            title:"Subway",
            distance:'4.8 rating, 650 miles',
            description:'Specializes in submarine sandwiches, wraps, salads and drinks',
            address:'Near Gate 1 & 2, Concourse A',
            img :'/assets/images/Subway_image.png',
            status:'inactive'
       },
       {   
           _id:103, 
            title:"Over-the-Rhine Market",
            distance:'4.5 rating, 500 miles',
            description:'Electronics, apparel, books/magazines, snacks, beverages and travel essentials',
            address:'Near Gate 4, Concourse A',
            img :'/assets/images/Ovder_the_rhine_image.png',
            status:'inactive'
        },
 

]

export const productlistdata=[
    {   id:1,
        title:'McAloo Tikki Burger',
        price:8,
        rating:4.8,
        description:'The World’s favourite Indian burger! Crunchy potato and peas patty with delicious Tom Mayo & crunchy onions; now with Whole Wheat Bun',
        image:'/assets/images/Image_McAloo.png',
        count:0,
    

    },
    {   id:2,
        title:'McVeggie Burger',
        price: 145,
        rating:3.8,
        description:'An everyday classic burger with a delectable patty filled with potatoes, carrots and tasty Indian spices; topped with crunchy lettuce and mayonaise.',
        image:'/assets/images/Image_McVeggle.png',
        count:0,
    
    },
 
    {    id:3,
        title:'Big Spicy Paneer Wrap',
        price:210,
        rating:4.8,
        description:'Rich & filling paneer patty coated in spicy crispy batter, topped with tom mayo sauce wrapped with lettuce, onions and cheese',
        image:'/assets/images/Big_Spicy_Paneer.png',
        count:0,
    
    },
    {   id:4,
        title:'Salad Wrap',
        price:145,
        rating:2.8,
        description:'An everyday classic burger with a delectable patty filled with potatoes, carrots and tasty Indian spices; topped with crunchy lettuce and mayonaise.',
        image:'/assets/images/Salad_wrap.png',
        count:0,
    
    }
]