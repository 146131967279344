import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Navigate, useNavigate } from 'react-router-dom';
function ProductPageHeader(props) {

    const navigate= useNavigate()
  return (
    <div className={props.open ?'Product_page_header_wrapper_two' :"Product_page_header_wrapper" }>

       <div className='Product_page_outer_div'>
          <div className='Product_page_inner_div_one'>   
              <HomeIcon sx={{color:'#40B4D0' , fontSize:'50px'}} onClick={()=>{
                navigate(-1)
              }}/>
              <div style={{textAlign:'left'}}>
                <h3> {props.storedata.state.title}</h3>
                <h5>{props.storedata.state.address}</h5>
             </div> 
         </div>
         <div className='Product_page_inner_div_two'> 
          {/* <FavoriteIcon sx={{color:'#40B4D0'}}/>  */}
          {/* <FavoriteBorderIcon/> */}
          <img src='/assets/images/SideLogo.svg'/>
        </div>
      </div>  
      <p> {props.storedata.state.description}</p>
    </div>
  )
}

export default ProductPageHeader
