import React, { useState } from 'react'
import Header from './Header'
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import { InputLabel, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DecrementCount, IncrementCount, otpaction } from '../redux/Actions';
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { getdeliverytime, orderbooking, placeorder, sendMessage, sendMessagetwo } from '../API';
import { useEffect } from 'react';
import OTPInput, { ResendOTP } from "otp-input-react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';

function Cart() {
  const otpresend = ()=> toast.success('OTP send Successfully')
  const otpSuccess = ()=> toast.success('Valid OTP ')
  const otpError = ()=> toast.error('Invalid OTP')
  const Bookingerror=()=> toast.error('Unexcepted Error')             
  const placeorderSuccess = ()=> toast.success('Order Place successfully ')
  const placeorderError = ()=> toast.error("Can't place order")
  const [isorderplace,setisorderplace] =useState(false)
  const cartdata= useLocation()
    const [otp, setOtp] = useState('');  
    const navigate =useNavigate()
    const [count,setcount] =useState(0) 
    const [newstring, setnewstring] =useState()
  
   let mobilenumber=cartdata?.state?.contactNo
    useEffect(()=>{
      let nstring = generateBarCode()
      setnewstring(nstring)
    },[])
  
    function generateBarCode()
    {
        // var nric = $('#text').val();
        let rString = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');  
        return rString
    }
    function randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
  }
   
 const verifyOTP=()=>{
  console.log('cartdata?.state?.cartData?.state',cartdata)
  let resendotp = localStorage.getItem('otp')
  let message="Hello there! Your order has been placed successfully. Thanks for placing an order!"
  let sendData= cartdata?.state?.cartData
  let itemname=[]
  let itemQuantity=[]
  // sendData.forEach((item)=>{
  //   itemname.push(item.itemName)
  // })
  // sendData.forEach((item)=>{
  //   itemQuantity.push(item.count)
  // })
  console.log('resendotpresendotp',resendotp)
  if(cartdata.state.message===parseInt(otp) || parseInt(otp) === parseInt(resendotp)){
    otpSuccess()
    let randomorderId = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
       const sendData=cartdata.state
      orderbooking(randomorderId,sendData).then((res)=>{
      console.log('Order Booking Response', res)
      if(res.data.status===200 || res.data.status==='200'){
        navigate('/redirecting', {state:{randomorderId,sendData}})
      }
  }).catch((err)=>{
      console.log('Error in Order Booking',err)
      Bookingerror()
  })
      
      
    
  
    
    // window.location.replace(`https://qaquickpay.hmshost.com/Menu/ByStore?orderId=${randomorderId}`)
 }
 else{
   console.log('Enter Valid OTP')
   otpError()
   console.log('resendotp',resendotp)
 }

 } 
 

  const dispatch =useDispatch()

  const { t } = useTranslation() 


 const handleresendOtp=()=>{
  let messageotp= Math.floor(100000 + Math.random() * 900000)
  localStorage.setItem('otp', messageotp)
  sendMessage(parseInt(cartdata.state.contactNo),messageotp)
  .then(res=>{
    console.log('OTP Send Success' ,res)
     if(res.data.status===200 ||res.data.status==="200" ){
      otpresend()
     }
           })
  .catch((err)=>{
   console.log('Error in send OTP',err)
   otpError()
  })
 } 
  return (
    <>
    <div className='MainWhole-wrapper-container'> 
    <Header/>
   
  <div className='CartPage-Main-wrapper'>  
  <h2> {t('cart_page_heading')}</h2>
     <h5 className='CartPage-Main-wrapper_h5'>
          {/* {t('cart_page_sub_heading')} */}
         We sent the code to your mobile number- <b>*** ***{cartdata.state.contactNo.substr(-5)}</b>
    </h5> 
   {/* {console.log('cartdatacartdata',cartdata)} */}

       <div className="CartPage-content-wrapper">
              
             <div className="DetailsPage-content-wrapper-inner"> 
               {/* <InputLabel className='detailspage-content-label'> {t('cart_page_Enter_otp')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
              <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false}  className="Cart_page_otp_input" />
            </div>
              <div className='Cart_page_Resend_button_wrapper'> 
                <p> Didn't get the code?</p> 

                <button onClick={()=>handleresendOtp()}> Resend Code</button>
              </div>
            
        </div>

            {/* <div className="DetailsPage-content-wrapper">
               <h2 className="CartPage-Product-wrapper-h2">
               {t('cart_page_View_Cart')}
               Cart
               </h2>
                  {cartdata?.state?.cartData?.map((item)=>{
                    if(item.count>0){
                     return (  <div className="ItemsListPage-ItemsList-containers">       
                     <div className='product-content-wrapper'> 
                                     <div className='product-content-wrapper-inner-div-one'> 
                                     <img src={item.image} className='product-images-class'/>
                                     </div>
                                     <div className='product-content-wrapper-inner-div-two'>
                                         <h4> {item.title}</h4>
                                         <p>€{item.price}</p>
                                     </div>
                     </div>
                 <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(DecrementCount(item, item.id))}
                 }> -</button>
                   <input type="number" readOnly value={item.count} className="ItemsListPage-ItemsList-Counter-input"/> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(IncrementCount(item, item.id))}}> +</button>  
                 </div>
              </div>)}
                  })}  
            </div>  */}
            <div className='Details_page_selected_deivery_location_wrapper_cartpage'> 
                  <h4> Selected gate </h4>
                  <p> {cartdata.state.locationName}</p>
              </div>
            <div className="Details-page-Nextbutton-wrapper"> 

            {isorderplace ? <button type="submit" disabled={true} style={{cursor:'pointer'}} onClick={()=>verifyOTP()}> Verify OTP<span>  <ArrowForwardIosIcon sx={{position: 'absolute',right: '12px',top: '20px'}}/></span></button>  :
             <button type="submit" style={{cursor:'pointer'}} onClick={()=>verifyOTP()}> Verify OTP <span>  <ArrowForwardIosIcon sx={{position: 'absolute',right: '12px',top: '20px'}}/></span></button>     
            }
             </div>  
      </div>
      <ToastContainer/>
      </div>    
      <Footer/> 
    </>
  )
}

export default Cart